<template>
    <div class="page1">
        <EButton @click="dialogShow">添加</EButton>
        <STable :tableTitle="tableTitle" :tableData="tableData" v-on:edit="edit">
            <template slot-scope="val" slot="edit">
                <EButton type="text" @click="editDialog(val.msg)">编辑</EButton>
<!--                <el-button type="text" @click="editDialog(val.msg)">编辑</el-button>-->
            </template>
            <template slot-scope="val" slot="sort">
                <EButton @click="getSort(val.msg.position_id,1)" type="text" :disabled="val.msg&&val.msg.sort==1">上移</EButton>
                <EButton @click="getSort(val.msg.position_id,2)" type="text" :disabled="val.msg&&val.msg.sort==val.msg.len">下移</EButton>
<!--                <el-button type="text" @click="getSort(val.msg.position_id,1)" :disabled="val.msg&&val.msg.sort==1">上移</el-button>-->
<!--                <el-button type="text" @click="getSort(val.msg.position_id,2)" :disabled="val.msg&&val.msg.sort==val.msg.len">下移</el-button>-->
            </template>
        </STable>
        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="cancelDialog">
            <EForm :formColumns="formColumns"
                   :labelPosition="labelPosition"
                   :rowSize="1"
                   :optionsBtn="false"
                   :labelWidth="labelWidth"
                   :actionBtn="true"
                   :formData="form"
                   :formRules="formRules"
                   ref="form"
                   @handleClick="saveData"
                   @handleClose="cancelDialog"
                   :searchFlag="false"
            ></EForm>
<!--            <el-form label-position="right" label-width="100px">-->
<!--                <el-form-item label="部门名称" >-->
<!--                    <el-cascader-->
<!--                            v-model="department_id"-->
<!--                            :options="options"-->
<!--                            @change="getParent"-->
<!--                            :disabled="!!form.position_id"></el-cascader>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="职位名称">-->
<!--                    <el-input placeHolder="最多可录入20字" maxlength="20" v-model="form.position_name	"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item>-->
<!--                    <el-button @click="cancelDialog">取消</el-button>-->
<!--                    <el-button type="primary" @click="addBtn">确定</el-button>-->
<!--                </el-form-item>-->
<!--            </el-form>-->
        </EDialog>
    </div>
</template>

<script>
  import STable from '@/components/STable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
import bus from "@/utils/bus";
  export default {
    name: 'Login',
    data() {
      return {
        tableTitle: [
          {
            label: '部门名称',
            prop: 'department_name',
          },
          {
            label: '职位名称',
            prop: 'position_name',
          },
          {
            label: '操作',
            prop: 'button',
          },
          {
            label: '排序',
            prop: 'button',
          }
        ],
        tableData: [],
        loading: false,
        dialogVisible: false,
        title: '添加职位',
        form: {
          position_name: '',
          position_id:'',
          department_id:''
        },
        options: [],
        department_id: [],
        formColumns:[
            {
          title:'部门名称',
          type:'cascader',
          property:'department_id',
          placeHolder:'请选择',
          require:true,
          show:true,
          options:[],
          checkStrictly:false
        },
          {
            title:'职位名称',
            type:'text',
            property:'position_name',
            placeHolder:'最多可录入20字',
            require:true,
            show:true
          },
          {
            type:'actionBtn',
            show:true
          }
        ],
        formRules:{
          department_id: [vxRule(true, '',"change", "部门名称不能为空")],
          position_name: [vxRule(true, '',"blur", "职位名称不能为空"),
            vxRule(true, val=>{
              return {result: val.length<=20, errMsg: "最多可录入20字"}})],
        },
        labelPosition:'right',
        labelWidth:'100px'
      }
    },
    watch: {},
    created() {
      this.positionLists()
    },
    components: {STable, EDialog, EButton,EForm},
    beforeMount() {

    },
    methods: {
      cancelDialog(){
        this.title='添加职位'
        this.dialogVisible=false
        this.form={
          position_name: '',
          position_id:'',
          department_id:''
        }
        this.department_id=[]
        this.formColumns[0].disabled=false
        this.$refs.form.$refs.form.resetFields()
      },
      getParent() {
        this.form.department_id=this.department_id[0]
      },
      dialogShow() {
        this.dialogVisible = true
        this.departmentOptionLists()
      },
      async departmentOptionLists() {
        let res = await Http.departmentOptionLists()
        this.formColumns[0].options=res.data
        // this.options = res.data
      },
      async positionLists() {
        let res = await Http.positionLists()
        this.tableData = res.data.list
      },
      saveData(){
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          if (valid && !this.error) {
            this.addBtn()
          } else {
            return false;
          }
        });
      },
      async addBtn() {
        let {
          position_name,
          position_id,
          department_id}=this.form
        let res = await Http.addOrEditPosition({

          position_name,
          position_id,
          department_id:department_id[0]
        })
        if(res.flag==1) {
          this.$message.success(res.msg)
          this.cancelDialog()
          this.positionLists()
        }
      },
      editDialog(data) {
        this.dialogShow()
        this.formColumns[0].disabled=true
        this.title='编辑职位'
        this.department_id=[data.department_id]
        this.form.department_id=[data.department_id]
        this.form.position_name = data.position_name
        this.form.position_id = data.position_id
      },
      async getSort(id, sort_type) {
        let res = await Http.setSortRule({id, sort_type, data_type: 'department_position'})
        this.$message.success(res.msg)
        this.positionLists()
      },
      edit(obj){

      }
    },
    beforeDestroy() {
    }
  }
</script>

<style lang="scss">
    .el-cascader{
        width: 100%;
    }
</style>

